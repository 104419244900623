/*Extend jQuery */
(function($, window, document, undefined){

	/*object.create method for old browsers*/
	if (typeof Object.create !== "function") {
	    Object.create = function (obj) {
	        function F() {}
	        F.prototype = obj;
	        return new F();
	    };
	}

    var Overlay = {
    	init : function(options, element){
    		this.options = $.extend({}, $.fn.wkpOverlay.options, options);
    		this.defaultZindex = this.options.topElements.css('z-index');
    		this.$element = $(element);
    		this.touch = Modernizr.touch;
			this.eventHandler();
    	},

    	eventHandler : function(){

    		if(this.touch && this.options.triggerEvent === 'hover'){
    			this.touchHandler();
    			return;
    		}

    		if(this.options.triggerEvent === 'hover'){
    			this.hoverHandler();
    		}else if(this.options.triggerEvent === 'click'){
    			this.clickHandler();
    		}else{
    			return false;
    		}
    	},

    	hoverHandler : function(){
    		var self = this;
    		this.$element.on({
    			mouseenter: function(){
    				self.setZindex();
    			},
    			mouseleave: function(){
    				self.unsetZindex();
    			}
    		});
    	},

    	clickHandler : function(){
    		var self = this;
    		this.$element.on('click', function(){
    			self.toggleZindex();
    		});
    	},

    	touchHandler : function(){
    		var self = this;

			if($(self.options.overlaySelector).is(':hidden')){
    			this.$element.on('touchstart', function(e){
    				self.show(this, e);
    			});
    		}else{
    			$('body').on('touchstart', function(e){
    				self.hide(this, e);
    			});
    		}
    	},

    	show : function(element, event){
    		var self = this;
				self.setZindex();
				$(element).off('touchstart');
				self.touchHandler();
    	},

    	hide : function(element, event){
    		var self = this;
    		if(self.options.preventFromHide !== undefined && self.options.preventFromHide.length > 0){
    			if(!$.contains(self.options.preventFromHide.get(0), event.target)){
	    			self.unsetZindex();
	    			$(element).off('touchstart');
					self.touchHandler();
	    		}
    		}else{
	    		if(!$.contains(self.$element.get(0), event.target)){
	    			self.unsetZindex();
	    			$(element).off('touchstart');
					self.touchHandler();
	    		}
    		}
    	},
    	
    	setZindex : function(){
    		var o = this.options;
    		o.topElements.css('z-index', '999');
    		o.topElements.addClass('topElement');
				o.overlaySelector.show().addClass(o.overlayClass);
    	},

    	unsetZindex : function(){
    		var o = this.options;
    		o.topElements.css('z-index', this.defaultZindex);
    		o.topElements.removeClass('topElement');
				o.overlaySelector.hide().removeClass(o.overlayClass);
    	},

    	toggleZindex : function(){
    		var o = this.options;
    		o.topElements.css('z-index', '999');
				o.overlaySelector.toggle().toggleClass(o.overlayClass);
    	},

    	destroy : function(){
    		var self = this;
    		self.unsetZindex();
    		if(self.options.triggerEvent === 'hover'){
    			self.$element.off('mouseenter mouseleave');
    		}else if(self.options.triggerEvent === 'click'){
    			self.$element.off('click');
    		}
    		self.$element.removeData()
    	}
    };

    $.fn.wkpOverlay = function( options ){
		return this.each(function() {
			if($(this).data("wkpOverlay-init") === true){
				return false;
			}
			$(this).data("wkpOverlay-init", true);
			var overlay = Object.create( Overlay );
			overlay.init( options, this );
			$.data( this, "wkpOverlay", overlay );
		});
	};

	// default options
  $.fn.wkpOverlay.options = {
  	triggerEvent : 'hover', //event that activate the overlay
		overlaySelector : $('.page-overlay'), //overlay layer
		overlayClass : 'active', //class to apply on the overlay layer when it's active
		topElements : $(this), //elements to bring on top of overlay
		preventFromHide : undefined
	};

}(jQuery, window, document));


$(document).ready(function() {

	//Set the overlay for the side navigation menu which is active 
	//both in mobile and desktop navigations
	$('#sidenav>ul>li.back').wkpOverlay({
		overlayClass : 'cover',
		topElements : $('#sidenav')
	});

	/*equalheight*/
	equalheight = function(container){
		var currentTallest = 0,
				currentRowStart = 0,
				rowDivs = new Array(),
				$el,
				topPosition = 0;
	 $(container).each(function() {

	   $el = $(this);
	   $($el).height('auto')
	   topPostion = $el.position().top;

	   if (currentRowStart != topPostion) {
	     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
	       rowDivs[currentDiv].height(currentTallest);
	     }
	     rowDivs.length = 0; // empty the array
	     currentRowStart = topPostion;
	     currentTallest = $el.height();
	     rowDivs.push($el);
	   } else {
	     rowDivs.push($el);
	     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
	  }
	   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
	     rowDivs[currentDiv].height(currentTallest);
	   }
	 });
	}
	$(window).load(function() {
	  equalheight('.thumbs-list li');
	});
	$(window).resize(function(){
	  equalheight('.thumbs-list li');
	});

	/*Main Slider*/
	$("#main_slider").owlCarousel({
		autoPlay : 7000,
		stopOnHover : true,
		navigation : true,
		slideSpeed : 1500,
		paginationSpeed : 1500,
		singleItem: true,
		navigationText : ["",""]
	}).hover(function(){
		$(this).css({
			'cursor' : '-webkit-grab'
		});
	});

	/* Teaser Slider */
	$(".teaser-slider").owlCarousel({
    autoPlay: false, //Set AutoPlay to 3 seconds
    items : 4,
    itemsTablet : [992,2],
    itemsMobile : [768,1],
  });


	var porscheUi = {
		/*Accordion menu*/
		'menuAccordion' : function(status, selector){
			var status = status || 'on';
			var selector = $(selector);
			var lists = selector.siblings('ul');

			if(status === 'on'){
				lists.hide();
				selector.each(function(){
					if($(this).siblings('ul').length !== 0){
						$(this).parent().addClass('dropdown');
						$(this).click(function(e){
							e.preventDefault();
							var thisParent = $(this).parent();
							if(thisParent.hasClass('open')){
								thisParent.removeClass('open').find('ul').hide('fast');
							}else{
								thisParent.addClass('open')
								.find('ul')
								.show('fast')
								.parent()
								.siblings()
								.removeClass('open')
								.find('ul')
								.hide('fast');
							}
						});
					}
				});
			}else if(status === 'off'){
				selector.parent().removeClass('dropdown open');
				lists.show();
				selector.off();
			}
		},
		/*Offcanvas menu*/
		'menuOffcanvas' : function(status, selector){
			var status = status || 'on';
			var selector = $(selector);
			var lists = selector.siblings('ul');

			if(status === 'on'){
				//lists.hide();
				selector.each(function(){
					if($(this).siblings('ul').length !== 0){
						$(this).parent().addClass('dropdown');
						$(this).click(function(e){
							e.preventDefault();
							var thisParent = $(this).parent();
							if(thisParent.hasClass('open')){
								thisParent.removeClass('open'); //.find('ul').hide('fast');
							}else{
								thisParent.addClass('open')
								.find('ul')
								//.show('fast')
								.parent()
								.siblings()
								.removeClass('open')
								.find('ul');
								//.hide('fast');
							}
						});
					}
				});
			}else if(status === 'off'){
				selector.parent().removeClass('dropdown open');
				lists.show();
				selector.off();
			}
		},
		/*Manage Offcanvas*/
		'toggleOffcanvas' : function(e){
			e.preventDefault();
			//e.data.selector.slideToggle(0);
			e.data.selector.toggleClass('is-open');
			$('.mobile-menu-button').removeClass('active')
		},
		'menuOffcanvasClose' : function(status, selector, trigger){
			var status = status || 'on';
			var selector = selector;
			var trigger = trigger;
			selector = $(selector);
			trigger = $(trigger);

			if(status == 'on'){
				//selector.hide();
				trigger.click({ selector: selector, trigger: trigger}, this.toggleOffcanvas);
			}else if(status == 'off'){
				//selector.show(0);
				trigger.off('click', this.toggleOffcanvas);
			}
		},
		'backOffcanvas' : function(e){
			e.preventDefault();
			e.data.trigger.parent().parent().removeClass('open');
		},
		'menuOffcanvasBack' : function(status, trigger){
			var status = status || 'on';
			var trigger = trigger;
			trigger = $(trigger);

			if(status == 'on'){
				trigger.click({ trigger: trigger}, this.backOffcanvas);
			}else if(status == 'off'){
				trigger.off('click', this.backOffcanvas);
			}
		},
		/*Manage main menu for mobile display*/
		'toggleMobileMenu' : function(e){
			e.preventDefault();
			e.data.trigger.toggleClass('active');
			//e.data.selector.slideToggle(0);
			e.data.selector.toggleClass('is-open');
			console.log('open-menu');
		},
		'menuMobile' : function(status, selector, trigger){
			var status = status || 'on';
			var selector = selector;
			var trigger = trigger;
			selector = $(selector);
			trigger = $(trigger);

			if(status == 'on'){
				//selector.hide();
				trigger.removeClass('active');
				trigger.click({ selector: selector, trigger: trigger}, this.toggleMobileMenu);
			}else if(status == 'off'){
				//selector.show(0);
				trigger.off('click', this.toggleMobileMenu);
			}
		}
	};

	/* MENU media */
	function mediaSize() { 
		if (window.matchMedia('(max-width: 720px)').matches) {
			$('body').addClass('mobile');

			//Set the submenu layout on main menu
	  	$('.main-menu .main-menu_container').removeClass('main-menu__submenu');
	  	//Unset the overlay for the main menu on mobile screensize
	  	if($('.mobile-menu-button').data("wkpOverlay-init") === true){
	  		$('.mobile-menu-button').data('wkpOverlay').destroy();
	  		porscheUi.menuMobile('on', '#top-menu', '.mobile-menu-button');
	  	}

		} else if (window.matchMedia('(max-width: 1022px)').matches) {

			//Reset mobile
			$('body').removeClass('mobile');
			$('.tools-menu__container').css('display', '');
			$('.main-menu__submenu').css('display', '');

			//Set the submenu layout on main menu
	  	$('.main-menu .main-menu_container').addClass('main-menu__submenu');

			//Set the overlay for the main menu on medium screensize
			$('.mobile-menu-button').wkpOverlay({
				triggerEvent : 'click',
				topElements : $('header')
			});
		} else{
			//Reset medium
			//Unset the submenu layout on main menu
	  	$('.main-menu .main-menu_container').removeClass('main-menu__submenu');

	  	//Unset the overlay for the main menu on medium screensize
	  	if($('.mobile-menu-button').data("wkpOverlay-init") === true){
	  		$('.mobile-menu-button').data('wkpOverlay').destroy();
	  		porscheUi.menuMobile('off', '#top-menu', '.mobile-menu-button');
	  	}
		}
	}

	mediaSize();

	window.addEventListener('resize', mediaSize, false);

	/* MENU small */
	enquire.register("screen and (max-width:1022px)", {
	  match : function() {
	  	$('body').addClass('mobile');
	  	porscheUi.menuMobile('on', '#top-menu', '.mobile-menu-button');
	  	porscheUi.menuMobile('on', '.tools-menu__container', '.tools-menu');
	  	porscheUi.menuOffcanvas('on', '.main-menu_container a');
	  	porscheUi.menuOffcanvasClose('on', '#top-menu', '[data-offcanvas-close]');
	  	porscheUi.menuOffcanvasBack('on', '[data-offcanvas-back]');
	  },
	  unmatch : function(){
	  	$('body').removeClass('mobile');
	  	porscheUi.menuMobile('off', '#top-menu', '.mobile-menu-button');
	  	porscheUi.menuMobile('off', '.tools-menu__container', '.tools-menu');
	  	porscheUi.menuOffcanvas('off', '.main-menu_container a');
	  	porscheUi.menuOffcanvasClose('off', '#top-menu', '[data-offcanvas-close]');
	  	porscheUi.menuOffcanvasBack('off', '[data-offcanvas-back]');
	  }
	});
	/* MENU large */
	enquire.register("screen and (min-width:1023px)", {
	  match : function() {
	  	$('.main-menu__submenu').css('display', '');
			//Set the overlay for the main menu on desktop screensize
			$('.main-menu_container li').has('.main-menu__submenu').wkpOverlay({
				topElements : $('header')
			});
	  },      
	  unmatch : function() {
	  	$('.main-menu__submenu').css('display', '');
	  	//Unset the overlay for the main menu on mobile screensize
	  	$('.main-menu_container li').has('.main-menu__submenu').each(function(){
	  		$(this).data('wkpOverlay').destroy();
	  	});
	  }
	});



	/* TOOLS MENU max-width:1023px*/
	enquire.register("screen and (max-width:1023px)", {
	    match : function() {
	    	//Set the overlay for the tools menu on mobile screensize
	    	$('.tools-menu, .tools-menu__container').wkpOverlay({
					topElements : $('header'),
					triggerEvent : 'click',
					preventFromHide : $('#top-menu')
				});

	    	$('.tools-menu').click(function(){
	    		$(this).toggleClass('open');

	    		if ($(this).siblings('.tools-menu__container').hasClass('open')){
	    			$('.tools-menu__container').toggleClass('open');
	    		}else{
	    			$('.tools-menu__container').toggleClass('open');
	    		}
				});
	    },      
	    unmatch : function() {
	    	$('.tools-menu').click(function(){
			    return false;
				});

				$('.tools-menu, .tools-menu__container').wkpOverlay({
					triggerEvent : 'hover',
				});
	    }
	});

	enquire.register("screen and (min-width:1023px)", {
	    match : function() {
	    	//Set the overlay for the tools menu on desktop screensize
    		$('.tools-menu, .tools-menu__container').wkpOverlay({
					topElements : $('header'),
					preventFromHide : $('#top-menu')
				});
	    },      
	    unmatch : function() {
	    	//Unset the overlay for the tools menu on mobile screensize
	    	if($('.tools-menu').length && $('.tools-menu').data('wkpOverlay').length) $('.tools-menu').data('wkpOverlay').destroy();
	    	if($('.tools-menu__container').length && $('.tools-menu__container').data('wkpOverlay').length) $('.tools-menu__container').data('wkpOverlay').destroy();
	    }
	});

	/* FOOTER accordion max-width:568px*/
	enquire.register("screen and (max-width:35.500em)", {
	    match : function() {
	    	porscheUi.menuAccordion('on', '.footer-menu__column h4');
	    },      
	    unmatch : function() {
	    	porscheUi.menuAccordion('off', '.footer-menu__column h4');
	    }
	});



	/* MODELS NAV */
	(function(){
		if(!Modernizr.touch) {
			$('.model-card').hover(function(){
				$(this).addClass('card--is-hovered');
				$(this).find('.model-card__headline').fadeOut('300');
			},function(){
				$(this).removeClass('card--is-hovered');
				$(this).find('.model-card__headline').fadeIn('300');
			});
		}
	})();

	/*tools-menu icon toggle on hover*/
	(function(){
		$('.tools-menu__container').hover(function(){
			$('.tools-menu').addClass('hovericon');
		},function(){
			$('.tools-menu').removeClass('hovericon');
		});
	})();


	/*sidenav breadcrumbs*/
	(function(){
		var pagePositionCheck = function(){
			if($(window).scrollTop() <= 0){
				$('#sidenav li.up').addClass('inactive');
			}else{
				$('#sidenav li.up').removeClass('inactive');
			}

			//Bottom page reached
			if ($(document).height() <= ($(window).height() + $(window).scrollTop())) {
	        	$('#sidenav li.down').addClass('inactive');
	    	}else{
	    		$('#sidenav li.down').removeClass('inactive');
	    	}
		};
		pagePositionCheck();
		$(window).scroll(function(){
			pagePositionCheck();
		});
		$('#sidenav li.up').click(function(){
			$('html, body').animate({scrollTop:0});
	        return false;
		});
		$('#sidenav li.down').click(function(){
			$('html, body').animate({scrollTop: $(document).height()});
	        return false;
		});
	})();


	if(Modernizr.touch) {
		$('.main-menu_container>li>a').on('click', function(e){
			if($(this).siblings('ul').length > 0){
				e.preventDefault();
			}
		});
	}

	//$(".standard-content").fitVids();


	/* Button Menu */
	(function(){
		$(".menu-button-sticky").stick_in_parent();

		if(!Modernizr.touch) {
			$('.menu-button').hover(function(){
				$(this).addClass('active');
			},function(){
				$(this).removeClass('active');
			});
		} else{
			$('.menu-button').on('click', function (e) {
				e.preventDefault();
				$(this).toggleClass('active')
			});
		}

		$('[data-trigger-top]').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 700);
    });

    $('[data-trigger-anchor]').on('click', function (e) {
	    e.preventDefault();
      var curLink = $(this);
      var scrollPoint = $(curLink.attr('href')).position().top;
      $('body,html').animate({
          scrollTop: scrollPoint - 60
      }, 700);
    });

    // menu icons behaviour
    function getRelatedNavigation(el){
		  return $('[data-trigger-anchor][href=#'+$(el).attr('id')+']');
		}

		$('[data-target-section]').each(function() {
	    new Waypoint({
	      element: this,
	      handler: function(direction) {
	        var previousWaypoint = this.previous()
	        var nextWaypoint = this.next()
	        var previousWaypoints = $(this.element).prevAll('[data-target-section]')

	        $('[data-trigger-anchor]').removeClass('link--previous link--active link--next');

	        getRelatedNavigation(this.element).addClass('link--active');

	        if (previousWaypoint) {
	        	$(previousWaypoints).each(function(){
	        		getRelatedNavigation(this).addClass('link--previous');
	        	})
	        }
	      },
	      offset: '0',
	    })
	  })
	})();


	/* phone numbers */
	(function(){
		var deviceAgent = navigator.userAgent.toLowerCase();
	  var agentID = deviceAgent.match(/(iphone|ipod|ipad|android|blackberry|iemobile)/);
	  if (!agentID) {
	    $('.js-phone').addClass('disabled');
	    $('.js-phone').on('click', function() {
	      return false;
	    });
	  }
	})();

});